import React from 'react';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import style from '../../style.module.scss';
import StatsBox from '../../../../components/stats-box';
import { useOnboardingStore } from '../../../../contexts/mobx';
import { friendlyNames, iconsMap, calculateDifference } from '../../utils';

export const OnboardingProgressionSection = observer(() => {
    const onboardingStore = useOnboardingStore();

    return (
        <div className={style.section_container}>
            <div className={style.main_container}>
                <div className={style.box_container}>
                    <h1 className={style.section_title}>
                        Onboarding Progression
                    </h1>
                    {onboardingStore.isFetchingProgressionData ? (
                        <Spin />
                    ) : (
                        <div className={style.row}>
                            {onboardingStore.progressionData.map(
                                (progression, index) => {
                                    const displayTitle =
                                        friendlyNames[progression.status] ||
                                        progression.status;
                                    const IconComponent =
                                        iconsMap[progression.status] ||
                                        iconsMap['IN_PROGRESS'];
                                    const previousProgression = onboardingStore.progressionDataPrevious.find(
                                        (prev) =>
                                            prev.status === progression.status
                                    );
                                    const differenceVal = previousProgression
                                        ? calculateDifference(
                                              progression.total,
                                              previousProgression.total
                                          )
                                        : 0;
                                    return (
                                        <StatsBox
                                            key={index}
                                            title={displayTitle}
                                            currentValue={progression.total}
                                            difference={differenceVal}
                                            icon={IconComponent}
                                        />
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
