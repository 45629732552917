import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './root';
import { HttpMethod } from '../types';
import * as yup from 'yup';

const onboardingDataSchema = yup.object({
    onboardingStepName: yup.string().required(),
    completedCount: yup.number().required(),
});

const onboardingDataListSchema = yup.object({
    onboardingData: yup.array(onboardingDataSchema).required(),
});

const progressionPeriodMetricsSchema = yup.object({
    status: yup
        .string()
        .oneOf(['IN_PROGRESS', 'COMPLETED', 'NOT_STARTED'])
        .required(),
    total: yup.number().required(),
});

export type OnboardingData = yup.InferType<typeof onboardingDataSchema>;

const segmentationDataSchema = yup.object({
    segmentationId: yup.number().required(),
    name: yup.string().required(),
    recordCount: yup.number().required(),
});

const segmentationDataListSchema = yup.object({
    segmentationData: yup.array(segmentationDataSchema).required(),
});

export type SegmentationData = yup.InferType<typeof segmentationDataSchema>;

const progressionPeriodMetricsListSchema = yup.object({
    progressionData: yup.array(progressionPeriodMetricsSchema).required(),
});

export type ProgressionPeriodMetrics = yup.InferType<
    typeof progressionPeriodMetricsSchema
>;

export class OnboardingStore {
    @observable onboardingData: OnboardingData[] = [];
    @observable onboardingDataPrevious: OnboardingData[] = [];
    @observable isFetchingOnboardingData = false;
    @observable segmentationData: SegmentationData[] = [];
    @observable segmentationDataPrevious: SegmentationData[] = [];
    @observable isFetchingSegmentationData = false;
    @observable progressionData: ProgressionPeriodMetrics[] = [];
    @observable progressionDataPrevious: ProgressionPeriodMetrics[] = [];
    @observable isFetchingProgressionData = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchSegmentationData(params: {
        startDate: string;
        endDate: string;
        periodKey?: string;
    }) {
        this.isFetchingSegmentationData = true;

        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/onboarding/segmentation/data',
                params: {
                    startDate: params.startDate,
                    endDate: params.endDate,
                },
            },
            segmentationDataListSchema
        );

        this.isFetchingSegmentationData = false;

        if (response.data?.segmentationData) {
            if (params.periodKey === 'previousPeriod') {
                this.segmentationDataPrevious = response.data.segmentationData;
            } else {
                this.segmentationData = response.data.segmentationData;
            }
        } else {
            if (params.periodKey === 'previousPeriod') {
                this.segmentationDataPrevious = [];
            } else {
                this.segmentationData = [];
            }
        }
    }

    @action
    async fetchOnboardingData(params: {
        startDate: string;
        endDate: string;
        periodKey?: string;
    }) {
        this.isFetchingOnboardingData = true;

        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/onboarding/data',
                params: {
                    startDate: params.startDate,
                    endDate: params.endDate,
                },
            },
            onboardingDataListSchema
        );

        this.isFetchingOnboardingData = false;

        if (response.data?.onboardingData) {
            if (params.periodKey === 'previousPeriod') {
                this.onboardingDataPrevious = response.data.onboardingData;
            } else {
                this.onboardingData = response.data.onboardingData;
            }
        } else {
            if (params.periodKey === 'previousPeriod') {
                this.onboardingDataPrevious = [];
            } else {
                this.onboardingData = [];
            }
        }
    }

    @action
    async fetchProgressionData(params: {
        startDate: string;
        endDate: string;
        periodKey?: string;
    }) {
        this.isFetchingProgressionData = true;

        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/onboarding/progression',
                params: {
                    startDate: params.startDate,
                    endDate: params.endDate,
                },
            },
            progressionPeriodMetricsListSchema
        );

        this.isFetchingProgressionData = false;

        if (response.data?.progressionData) {
            if (params.periodKey === 'previousPeriod') {
                this.progressionDataPrevious = response.data.progressionData;
            } else {
                this.progressionData = response.data.progressionData;
            }
        } else {
            if (params.periodKey === 'previousPeriod') {
                this.progressionDataPrevious = [];
            } else {
                this.progressionData = [];
            }
        }
    }
}
