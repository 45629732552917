import React, { useEffect } from 'react';
import { Modal, Table, Spin, Typography, Tag } from 'antd';
import { observer } from 'mobx-react';
import styles from './style.module.scss';
import moment from 'moment';
import { useClientStore } from '../../../../../contexts/mobx';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    CloseCircleOutlined,
    QuestionCircleOutlined,
    UserOutlined,
    SettingOutlined,
    MailOutlined,
    LockOutlined,
    SafetyOutlined,
    GiftOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

interface OnboardingModalProps {
    isVisible: boolean;
    onClose: () => void;
    clientId?: number;
}

interface OnboardingStep {
    stepDoneAt?: string;
    action: string;
    userResponse?: string | null;
    id: number;
}

const formatLabel = (label: string) =>
    label.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

const getProgressionColor = (progression: string) => {
    switch (progression) {
        case 'IN_PROGRESS':
            return 'orange';
        case 'COMPLETED':
            return 'green';
        case 'FAILED':
            return 'red';
        default:
            return 'gray';
    }
};

const getProgressionIcon = (progression: string) => {
    switch (progression) {
        case 'IN_PROGRESS':
            return <ExclamationCircleOutlined style={{ color: 'orange' }} />;
        case 'COMPLETED':
            return <CheckCircleOutlined style={{ color: 'green' }} />;
        case 'FAILED':
            return <CloseCircleOutlined style={{ color: 'red' }} />;
        default:
            return <QuestionCircleOutlined style={{ color: 'gray' }} />;
    }
};

const getActionIcon = (action: string) => {
    switch (action.toLowerCase()) {
        case 'user_segmentation_setup':
            return <UserOutlined />;
        case 'profile_setup':
            return <SettingOutlined />;
        case 'account_setup':
            return <MailOutlined />;
        case 'permissions_setup':
            return <LockOutlined />;
        case 'phone_confirmation_setup':
            return <SafetyOutlined />;
        case 'congratulations_setup':
            return <GiftOutlined />;
        default:
            return <QuestionCircleOutlined />;
    }
};

const renderDetails = (response?: string | null) => {
    if (!response) {
        return <Text type="secondary">---</Text>;
    }

    try {
        const parsedResponse = JSON.parse(response);
        const push =
            parsedResponse.Push !== undefined ? (
                <Tag color={parsedResponse.Push ? 'green' : 'red'}>
                    Push: {parsedResponse.Push.toString()}
                </Tag>
            ) : null;
        const location =
            parsedResponse.Location !== undefined ? (
                <Tag color={parsedResponse.Location ? 'green' : 'red'}>
                    Location: {parsedResponse.Location.toString()}
                </Tag>
            ) : null;

        return (
            <div style={{ display: 'flex', gap: '8px' }}>
                {push}
                {location}
            </div>
        );
    } catch {
        return <Tag color="green">{response}</Tag>;
    }
};

export const OnboardingModal = ({
    isVisible,
    onClose,
    clientId,
}: OnboardingModalProps) => {
    const clientStore = useClientStore();

    useEffect(() => {
        if (isVisible && clientId) {
            clientStore.fetchOnboardingSteps(clientId);
        }
    }, [isVisible, clientId, clientStore]);

    const safeProgression = clientStore.onboardingProgression ?? 'UNKNOWN';

    const columns = [
        {
            title: formatLabel('date'),
            dataIndex: 'stepDoneAt',
            key: 'stepDoneAt',
            sorter: (a: OnboardingStep, b: OnboardingStep) =>
                moment(a.stepDoneAt).unix() - moment(b.stepDoneAt).unix(),
            render: (stepDoneAt?: string) =>
                stepDoneAt ? (
                    <div>
                        <Text strong style={{ fontSize: '14px' }}>
                            {moment(stepDoneAt).format('h:mm A')}
                        </Text>
                        <br />
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                            {moment(stepDoneAt).format('MMM D, YYYY')}
                        </Text>
                    </div>
                ) : (
                    'N/A'
                ),
        },
        {
            title: formatLabel('action'),
            dataIndex: 'action',
            key: 'action',
            render: (action: string) => (
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    {getActionIcon(action)}
                    {formatLabel(action)}
                </span>
            ),
        },
        {
            title: formatLabel('details'),
            dataIndex: 'userResponse',
            key: 'userResponse',
            render: renderDetails,
        },
    ];

    return (
        <Modal
            title={
                <>
                    <Text strong>Onboarding Progression:</Text>{' '}
                    {getProgressionIcon(safeProgression)}{' '}
                    <Text
                        strong
                        style={{ color: getProgressionColor(safeProgression) }}
                    >
                        {formatLabel(safeProgression)}
                    </Text>
                </>
            }
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            centered
            width={800}
            className={styles.onboardingModal}
        >
            {clientStore.isFetchingOnboardingSteps ? (
                <div className={styles.loadingContainer}>
                    <Spin size="large" />
                </div>
            ) : (
                <Table
                    columns={columns}
                    dataSource={clientStore.onboardingSteps?.steps ?? []}
                    rowKey="id"
                    pagination={false}
                    className={styles.onboardingTable}
                />
            )}
        </Modal>
    );
};

export default observer(OnboardingModal);
