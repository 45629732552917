import React from 'react';
import { observer } from 'mobx-react';
import { useOktaAuth } from '@okta/okta-react';
import style from './style.module.scss';
import { useAuthStore, useUserStore } from '../../contexts/mobx';
import { useLocation } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { useNavigation } from '../../utils/use-navigation';
import {
    CategoriesPage,
    CategoryPage,
    ClientsPage,
    FeedbacksPage,
    VisitsPage,
    GeneralConfigsPage,
    PromotionsPage,
    PromotionPage,
    PromotionGrantsPage,
    OffersPage,
    OfferPage,
    VisiblePromotionsPage,
    FeaturedPage,
    FeaturedSinglePage,
    VisibleFeaturedPage,
    StoresDiffPage,
    StoresPage,
    GamesPage,
    GameSportPage,
    GameExternalPage,
    DashboardPage,
    NhlGamesPage,
    NhlGamePage,
    NhlGamesDashboardPage,
    NhlGamesLeaderboardPage,
    ProfilePage,
    AcvtPage,
    CareersPage,
    CareerPage,
    RechargeImagesPage,
    RechargeImagePage,
    WelcomePage,
    OnboardingPage,
} from '../index';
import { Layout, Menu } from 'antd';
import { SelectParam } from 'antd/lib/menu';
import { ReactComponent as StrategyIcon } from '../../icons/Strategy.svg';
import { ReactComponent as HouseIcon } from '../../icons/House.svg';
import { ReactComponent as ChatsIcon } from '../../icons/Chats.svg';
import { ReactComponent as ChartlineIcon } from '../../icons/ChartLine.svg';
import { ReactComponent as MapPinIcon } from '../../icons/MapPin.svg';
import { ReactComponent as PathIcon } from '../../icons/Path.svg';
import { ReactComponent as SlidersIcon } from '../../icons/SlidersHorizontal.svg';
import { ReactComponent as TicketIcon } from '../../icons/Ticket.svg';
import { ReactComponent as TrophyIcon } from '../../icons/Trophy.svg';
import { ReactComponent as UserIcon } from '../../icons/User.svg';
import { ReactComponent as ArrowLineRightIcon } from '../../icons/ArrowLineRight.svg';
import { ReactComponent as CareerIcon } from '../../icons/CareerIcon.svg';
import { ReactComponent as ThunderboltIcon } from '../../icons/Thunderbolt.svg';
import { ReactComponent as CarwashMenuIcon } from '../../icons/CarwashMenuIcon.svg';
import { Text } from '../../utils/text';
import { CarWashPage } from '../car-wash-page';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

export const HomePage = observer(() => {
    const { oktaAuth } = useOktaAuth();
    const userStore = useUserStore();
    const location = useLocation();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const {
        isCashier,
        hasManagementAccess,
        isCustomerServiceAgent,
    } = authStore;

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const onItemSelected = (param: SelectParam) => {
        switch (param.key) {
            case 'log-out':
                oktaAuth.signOut();
                break;
            case 'dashboard':
                navigation.goToDashboard();
                break;
            case 'onboarding':
                navigation.goToOnboarding();
                break;
            case 'nhl-games':
                navigation.goToNhlGames();
                break;
            case 'nhl-games-dashboard':
                navigation.goToNhlGamesDashboard();
                break;
            case 'nhl-games-leaderboard':
                navigation.goToNhlGamesLeaderboard();
                break;
            case 'clients':
                navigation.goToClients();
                break;
            case 'feedbacks':
                navigation.goToFeedbacks();
                break;
            case 'visits':
                navigation.goToVisits();
                break;
            case 'general-configs':
                navigation.goToConfig();
                break;
            case 'promotions':
                navigation.goToPromotions();
                break;
            case 'categories':
                navigation.goToCategories();
                break;
            case 'offers':
                navigation.goToOffers();
                break;
            case 'visible-promotions':
                navigation.goToVisiblePromotions();
                break;
            case 'featured':
                navigation.goToFeaturedList();
                break;
            case 'visible-featured':
                navigation.goToFeaturedVisibleList();
                break;
            case 'app-preview':
                navigation.goToAppPreview();
                break;
            case 'stores':
                navigation.goToStores();
                break;
            case 'games':
                navigation.goToGames();
                break;
            case 'profile':
                navigation.goToProfile();
                break;
            case 'careers':
                navigation.goToCareers();
                break;
            case 'recharge-images':
                navigation.goToRechargesImages();
                break;
            case 'car-wash':
                navigation.goToCarWash();
                break;
            default:
                navigation.goToHome();
        }
    };

    return (
        <div className={style.home_container}>
            <Layout>
                <Sider
                    width={240}
                    breakpoint={isCashier ? undefined : 'lg'}
                    collapsedWidth="0"
                    className={style.sider}
                    onBreakpoint={(broken) => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                    collapsible={isCashier}
                    defaultCollapsed={isCashier}
                    zeroWidthTriggerStyle={{
                        background: 'lightgrey',
                        color: 'black',
                        opacity: '0.5',
                    }}
                >
                    <div className={style.logo}>
                        <img
                            loading="lazy"
                            src={process.env.PUBLIC_URL + '/ct-logo.png'}
                            alt="logo"
                        />
                        Couche-Tard CMS
                    </div>
                    <Menu
                        onSelect={onItemSelected}
                        theme="light"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                    >
                        {(hasManagementAccess || isCustomerServiceAgent) && (
                            <Menu.Item key="dashboard" icon={<ChartlineIcon />}>
                                Dashboard
                            </Menu.Item>
                        )}
                        {hasManagementAccess && (
                            <Menu.Item key="onboarding" icon={<UserIcon />}>
                                Onboarding
                            </Menu.Item>
                        )}
                        {hasManagementAccess && (
                            <SubMenu
                                key="sub home"
                                title="App Hero Banner"
                                icon={<HouseIcon />}
                            >
                                <Menu.Item key="featured">Featured</Menu.Item>
                                <Menu.Item key="visible-featured">
                                    Visible Featured
                                </Menu.Item>
                                <Menu.Item key="app-preview">
                                    App Preview
                                </Menu.Item>
                            </SubMenu>
                        )}
                        {hasManagementAccess && (
                            <Menu.Item
                                key="car-wash"
                                icon={<CarwashMenuIcon />}
                                className={style.carwash_icon}
                            >
                                Car-Wash
                            </Menu.Item>
                        )}
                        {hasManagementAccess && (
                            <SubMenu
                                key="sub nhl"
                                title={Text.homePage.navBar.nhlSection.title}
                                icon={<StrategyIcon />}
                            >
                                <Menu.Item key="nhl-games-dashboard">
                                    {Text.homePage.navBar.nhlSection.dashboard}
                                </Menu.Item>
                                <Menu.Item key="nhl-games-leaderboard">
                                    {
                                        Text.homePage.navBar.nhlSection
                                            .leaderboard
                                    }
                                </Menu.Item>
                                <Menu.Item key="nhl-games">
                                    {Text.homePage.navBar.nhlSection.config}
                                </Menu.Item>
                            </SubMenu>
                        )}
                        {hasManagementAccess || isCustomerServiceAgent ? (
                            <Menu.Item key="clients" icon={<UserIcon />}>
                                {Text.homePage.navBar.clients}
                            </Menu.Item>
                        ) : null}
                        {hasManagementAccess || isCustomerServiceAgent ? (
                            <Menu.Item key="feedbacks" icon={<ChatsIcon />}>
                                Feedback
                            </Menu.Item>
                        ) : null}
                        <Menu.Item key="visits" icon={<PathIcon />}>
                            Visits
                        </Menu.Item>
                        {hasManagementAccess && (
                            <Menu.Item key="stores" icon={<MapPinIcon />}>
                                Stores
                            </Menu.Item>
                        )}
                        {hasManagementAccess && (
                            <Menu.Item key="games" icon={<TrophyIcon />}>
                                Games & Contests
                            </Menu.Item>
                        )}
                        {hasManagementAccess && (
                            <Menu.Item key="careers" icon={<CareerIcon />}>
                                Careers
                            </Menu.Item>
                        )}
                        {hasManagementAccess && (
                            <SubMenu
                                key="sub2"
                                title="Recharge"
                                icon={<ThunderboltIcon />}
                            >
                                <Menu.Item key="recharge-images">
                                    Images
                                </Menu.Item>
                            </SubMenu>
                        )}
                        {hasManagementAccess && (
                            <SubMenu
                                key="sub1"
                                title="Promotions & cie"
                                icon={<TicketIcon />}
                            >
                                <Menu.Item key="promotions">
                                    Promotions
                                </Menu.Item>
                                <Menu.Item key="visible-promotions">
                                    Visible Promotions
                                </Menu.Item>
                                <Menu.Item key="categories">
                                    Categories
                                </Menu.Item>
                                <Menu.Item key="offers">
                                    Promotional Offers
                                </Menu.Item>
                            </SubMenu>
                        )}

                        <SubMenu
                            key="sub3"
                            title="Settings"
                            icon={<SlidersIcon />}
                        >
                            <Menu.Item key="profile">Profile</Menu.Item>
                            {hasManagementAccess && (
                                <Menu.Item key="general-configs">
                                    Configuration
                                </Menu.Item>
                            )}
                        </SubMenu>

                        <Menu.Item
                            key="log-out"
                            icon={<ArrowLineRightIcon />}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                marginBottom: '45px',
                            }}
                        >
                            Log out
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout
                    className={isCashier ? style.cashier_layout : style.layout}
                >
                    <Header className={style.header}>
                        Welcome {userStore.user?.firstName}
                    </Header>
                    <Content className={style.content}>
                        <div className={style.content_box}>
                            <Switch>
                                <Route path="/dashboard">
                                    <DashboardPage />
                                </Route>
                                <Route path="/onboarding">
                                    <OnboardingPage />
                                </Route>
                                <Route path="/nhl-games/:action/:id?">
                                    <NhlGamePage />
                                </Route>
                                <Route path="/nhl-games">
                                    <NhlGamesPage />
                                </Route>
                                <Route path="/nhl-games-dashboard">
                                    <NhlGamesDashboardPage />
                                </Route>
                                <Route path="/nhl-games-leaderboard">
                                    <NhlGamesLeaderboardPage />
                                </Route>
                                <Route path="/clients">
                                    <ClientsPage />
                                </Route>
                                <Route path="/feedbacks">
                                    <FeedbacksPage />
                                </Route>
                                <Route path="/visits">
                                    <VisitsPage />
                                </Route>
                                <Route path="/categories">
                                    <CategoriesPage />
                                </Route>
                                <Route path="/category/:action/:id?">
                                    <CategoryPage />
                                </Route>
                                <Route path="/general-configs">
                                    <GeneralConfigsPage />
                                </Route>
                                <Route path="/promotions">
                                    <PromotionsPage />
                                </Route>
                                <Route path="/visible-promotions">
                                    <VisiblePromotionsPage />
                                </Route>
                                <Route path="/promotion/:id/grants">
                                    <PromotionGrantsPage />
                                </Route>
                                <Route path="/promotion/:action/:id?">
                                    <PromotionPage />
                                </Route>
                                <Route path="/offers">
                                    <OffersPage />
                                </Route>
                                <Route path="/offer/:action/:id?">
                                    <OfferPage />
                                </Route>
                                <Route path="/featured/:action/:id?">
                                    <FeaturedSinglePage />
                                </Route>
                                <Route path="/featured">
                                    <FeaturedPage />
                                </Route>
                                <Route path="/visible-featured">
                                    <VisibleFeaturedPage />
                                </Route>
                                <Route path="/stores">
                                    <StoresPage />
                                </Route>
                                <Route path="/stores-diff">
                                    <StoresDiffPage />
                                </Route>
                                <Route path="/games">
                                    <GamesPage />
                                </Route>
                                <Route path="/game-sport/:action/:id?">
                                    <GameSportPage />
                                </Route>
                                <Route path="/game-sport/:action">
                                    <GameSportPage />
                                </Route>
                                <Route path="/game-external/:action/:id?">
                                    <GameExternalPage />
                                </Route>
                                <Route path="/game-external/:action">
                                    <GameExternalPage />
                                </Route>
                                <Route path="/profile">
                                    <ProfilePage />
                                </Route>
                                <Route path="/app-preview">
                                    <AcvtPage />
                                </Route>
                                <Route path="/careers">
                                    <CareersPage />
                                </Route>
                                <Route path="/career/:action/:id?">
                                    <CareerPage />
                                </Route>
                                <Route path="/recharge/images/:action/:id?">
                                    <RechargeImagePage />
                                </Route>
                                <Route path="/recharge">
                                    <RechargeImagesPage />
                                </Route>
                                <Route path="/car-wash">
                                    <CarWashPage />
                                </Route>
                                <Route path="/">
                                    {isCashier ? (
                                        <VisitsPage />
                                    ) : (
                                        <WelcomePage />
                                    )}
                                </Route>
                            </Switch>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
});
