import React from 'react';
import { RootStore } from '../stores/root';

const rootStore = new RootStore();
const storeContext = React.createContext<RootStore | null>(null);

interface StoreProviderProps {
    children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
    return (
        <storeContext.Provider value={rootStore}>
            {children}
        </storeContext.Provider>
    );
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        throw new Error('useStore must be used within a StoreProvider.');
    }
    return store;
};

export const useAuthStore = () => {
    const store = useStore();
    return store.authStore;
};

export const useUserStore = () => {
    const store = useStore();
    return store.userStore;
};

export const useConfigStore = () => {
    const store = useStore();
    return store.configStore;
};

export const usePromotionStore = () => {
    const store = useStore();
    return store.promotionStore;
};

export const useCategoryStore = () => {
    const store = useStore();
    return store.categoryStore;
};

export const useOfferStore = () => {
    const store = useStore();
    return store.offerStore;
};

export const useFeaturedStore = () => {
    const store = useStore();
    return store.featuredStore;
};

export const useClientStore = () => {
    const store = useStore();
    return store.clientStore;
};

export const usePromotionGrantStore = () => {
    const store = useStore();
    return store.promotionGrantStore;
};

export const useVisitStore = () => {
    const store = useStore();
    return store.visitStore;
};

export const useFeedbackStore = () => {
    const store = useStore();
    return store.feedbackStore;
};

export const useFeedbackNPSStore = () => {
    const store = useStore();
    return store.npsStore;
};

export const useStoresStore = () => {
    const store = useStore();
    return store.storesStore;
};

export const useGamesStore = () => {
    const store = useStore();
    return store.gamesStore;
};

export const useSmsHistoryStore = () => {
    const store = useStore();
    return store.smsHistoryStore;
};

export const useAnalyticsStore = () => {
    const store = useStore();
    return store.AnalyticsStore;
};

export const useNhlGamesStore = () => {
    const store = useStore();
    return store.nhlGamesStore;
};

export const useAcvtStore = () => {
    const store = useStore();
    return store.acvtStore;
};

export const useCareerStore = () => {
    const store = useStore();
    return store.careerStore;
};
export const useRechargeStore = () => {
    const store = useStore();
    return store.rechargeStore;
};

export const useCarWashStore = () => {
    const store = useStore();
    return store.carWashStore;
};

export const useOnboardingStore = () => {
    const store = useStore();
    return store.onboardingStore;
};
