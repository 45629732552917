import { useHistory } from 'react-router-dom';
import {
    END_DATE,
    PER_PAGE,
    PER_SMALL_PAGE,
    START_DATE,
    DASHBOARD_ACTIVE_USER_START_DATE,
    DASHBOARD_ACTIVE_USER_END_DATE,
    DASHBOARD_NHL_PER_PAGE,
    START_DATE_30_DAYS,
} from './const';
import { GameType } from '../types/games';

export const useNavigation = () => {
    const history = useHistory();

    return {
        // Home
        goToHome() {
            history.push('/');
        },
        // Featured
        replaceToFeaturedList() {
            history.replace(`/featured?page=0&perPage=${PER_PAGE}`);
        },
        goToFeaturedList() {
            history.push(`/featured?page=0&perPage=${PER_PAGE}`);
        },
        goToFeaturedVisibleList() {
            history.push(`/visible-featured`);
        },
        replaceToFeaturedCreation() {
            history.replace('/featured/create');
        },
        goToFeaturedEdit(id: number) {
            history.push(`/featured/edit/${id}`);
        },
        // Client
        goToClients(q = '') {
            history.push(`/clients?page=0&perPage=${PER_PAGE}&q=${q}`);
        },
        // Dashbord Analytics
        goToDashboard() {
            history.push(
                `/dashboard?startDate=${DASHBOARD_ACTIVE_USER_START_DATE}&endDate=${DASHBOARD_ACTIVE_USER_END_DATE}`
            );
        },
        // NHL Section
        goToNhlGames() {
            history.push(`/nhl-games?page=0&perPage=${PER_PAGE}`);
        },
        replaceToNhlGame() {
            history.replace(`/nhl-games?page=0&perPage=${PER_PAGE}`);
        },
        goToNhlGamesDashboard() {
            history.push(
                `/nhl-games-dashboard?page=0&perPage=${DASHBOARD_NHL_PER_PAGE}&startDate=${START_DATE}&endDate=${END_DATE}&limit=32`
            );
        },
        goToOnboarding() {
            history.push(
                `/onboarding?startDate=${START_DATE_30_DAYS}&endDate=${END_DATE}`
            );
        },
        goToNhlGamesLeaderboard() {
            history.push(`/nhl-games-leaderboard?page=0&perPage=20`);
        },
        goToNhlGameEdit(id: number) {
            history.push(`/nhl-games/edit/${id}?limit=10`);
        },
        goToNhlGameCreate() {
            history.push(`/nhl-games/create`);
        },
        // Feedback
        goToFeedbacks() {
            history.push(
                `/feedbacks?page=0&perPage=${PER_PAGE}&startDate=${START_DATE}&endDate=${END_DATE}`
            );
        },
        // Visits
        goToVisits() {
            history.push(`/visits?page=0&perPage=${PER_SMALL_PAGE}`);
        },
        // Config
        goToConfig() {
            history.push('/general-configs');
        },
        // Promotions
        goToPromotions() {
            history.push(`/promotions?page=0&perPage=${PER_PAGE}`);
        },
        replaceToPromotions() {
            history.replace(`/promotions?page=0&perPage=${PER_PAGE}`);
        },
        goToVisiblePromotions() {
            history.push('/visible-promotions');
        },
        goToPromotionEdit(id: number) {
            history.push(`/promotion/edit/${id}`);
        },
        goToPromotionCreate() {
            history.push(`/promotion/create`);
        },
        goToPromotionDuplicate(id: number) {
            history.push(`/promotion/duplicate/${id}`);
        },
        replaceToPromotionCreate() {
            history.push(`/promotion/create`);
        },
        // Promotion grants
        goToGrants(id: number) {
            history.push(`/promotion/${id}/grants`);
        },
        // Stores
        goToStores() {
            history.push('/stores');
        },
        goToStoresDiff() {
            history.push('/stores-diff');
        },
        replaceToStores() {
            history.replace('/stores');
        },
        // Games
        goToGames() {
            history.push('/games');
        },
        replaceToGames() {
            history.replace('/games');
        },
        goToGameEdit(id: number, type: GameType) {
            history.push(`/game-${type}/edit/${id}`);
        },
        goToGameCreate(type: GameType) {
            history.push(`/game-${type}/create`);
        },
        // Categories
        goToCategories() {
            history.push(`/categories?page=0&perPage=${PER_PAGE}`);
        },
        replaceToCategories() {
            history.replace(`/categories?page=0&perPage=${PER_PAGE}`);
        },
        goToCategoryEdit(id: number) {
            history.push(`/category/edit/${id}`);
        },
        goToCategoryCreate() {
            history.push(`/category/create`);
        },
        replaceToCategoryCreate() {
            history.replace(`/category/create`);
        },
        // Offers
        goToOffers() {
            history.push(`/offers?page=0&perPage=${PER_PAGE}`);
        },
        replaceToOffers() {
            history.replace(`/offers?page=0&perPage=${PER_PAGE}`);
        },
        goToOfferEdit(id: number) {
            history.push(`/offer/edit/${id}`);
        },
        goToOfferCreate() {
            history.push(`/offer/create`);
        },
        replaceToOfferCreate() {
            history.replace(`/offer/create`);
        },
        goToProfile() {
            history.push('/profile');
        },
        goToAppPreview() {
            history.push('/app-preview');
        },
        goToCareers() {
            history.push('/careers');
        },
        goToCareerEdit(id: number) {
            history.push(`/career/edit/${id}`);
        },
        goToCareerCreate() {
            history.push('/career/create');
        },
        goToRechargesImages() {
            history.push('/recharge');
        },
        goToRechargeEdit(id: number) {
            history.push(`/recharge/images/edit/${id}`);
        },
        goToRechargeCreate() {
            history.push('/recharge/images/create');
        },
        goToCarWash() {
            history.push('/car-wash');
        },
    };
};
