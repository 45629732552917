import moment from 'moment';
import 'moment-timezone';

export type PromotionTypes = 'in_store' | 'coupon';
export const PromotionTypes = ['in_store', 'coupon'] as const;
export const PromotionTypesRegEx = /in_store|coupon/;

export type PromotionStatuses =
    | 'upcoming'
    | 'activatable'
    | 'expired'
    | 'ended';
export const PromotionStatuses = [
    'upcoming',
    'activatable',
    'expired',
    'ended',
] as const;

export const PER_PAGE = 50;
export const DASHBOARD_NHL_PER_PAGE = 10;
export const PER_SMALL_PAGE = 2;

export const START_DATE = moment().subtract(3, 'months').toISOString();
export const START_DATE_30_DAYS = moment().subtract(30, 'days').toISOString();
export const END_DATE = moment().toISOString();

export const DASHBOARD_ACTIVE_USER_START_DATE = moment()
    .tz('America/Toronto')
    .subtract(7, 'days')
    .format('YYYY-MM-DD');
export const DASHBOARD_ACTIVE_USER_END_DATE = moment()
    .tz('America/Toronto')
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

export type FeaturedTypes =
    | 'promotion'
    | 'external_url'
    | 'promotions/in_store'
    | 'promotions/coupons'
    | 'stores'
    | 'ct-connect'
    | 'car-wash'
    | 'games';
export const FeaturedTypes = [
    'promotion',
    'external_url',
    'promotions/in_store',
    'promotions/coupons',
    'stores',
    'ct-connect',
    'car-wash',
    'games',
] as const;

export type FeedbackTypes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export const FeedbackTypes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

export const FeaturedTypesRegEx = /promotion|external_url|promotions\/in_store|promotion\/coupons|stores|ct-connect|car-wash|games/;

export const FeatureTypeColors: { [key in FeaturedTypes]: string } = {
    promotion: 'magenta',
    external_url: 'red',
    'promotions/in_store': 'orange',
    'promotions/coupons': 'lime',
    stores: 'cyan',
    'ct-connect': 'blue',
    'car-wash': 'purple',
    games: 'green',
};

export const FeedbackTypeColors: { [key in FeedbackTypes]: string } = {
    0: 'red',
    1: 'red',
    2: 'red',
    3: 'red',
    4: 'red',
    5: 'red',
    6: 'red',
    7: 'orange',
    8: 'orange',
    9: 'green',
    10: 'green',
};

export type ActiveQueryParam = 'active' | 'inactive';
export const ActiveQueryParam = ['active', 'inactive'] as const;

export type AnalyticsCompareOptions = 'preceding' | 'yearly' | 'custom';

export const ANALYTICS_DEFAULT_DATE_RANGE = 28;
export const ANALYTICS_DEFAULT_COMPARE_OPTION: AnalyticsCompareOptions =
    'preceding';

export type RANGE_PROPERTIES = 'current' | 'compare' | undefined;

export const coucheTardRed = '#da291c';
export const activeGreen = '#24B040';
export const pendingOrange = 'darkorange';
export const inactiveGrey = '#6a7581';

export const defaultNumberOfDays = 7;
