import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DatePicker } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import type { RangeValue } from 'rc-picker/lib/interface';
import { useOnboardingStore, useAuthStore } from '../../contexts/mobx';
import { useNavigation } from '../../utils/use-navigation';
import { useQueryParams } from '../../utils/use-query-params';
import { PageHeader } from '../../components/page-header';
import style from './style.module.scss';
import { OnboardingProgressionSection } from './components/OnboardingProgressionSection';
import { OnboardingStepsSection } from './components/OnboardingStepsSection';
import { SegmentationSurveySection } from './components/SegmentationSurveySection';

const { RangePicker } = DatePicker;

type DateRangeQueryParams = {
    startDate?: string;
    endDate?: string;
};

export const OnboardingPage = observer(() => {
    const authStore = useAuthStore();
    const onboardingStore = useOnboardingStore();
    const navigation = useNavigation();
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [dateRangeParams, , , setManyParamsAndGoToFirstPage] = useQueryParams<
        DateRangeQueryParams
    >();

    useEffect(() => {
        if (
            !authStore.hasManagementAccess &&
            !authStore.isCustomerServiceAgent
        ) {
            navigation.goToHome();
        }
    }, [
        authStore.hasManagementAccess,
        authStore.isCustomerServiceAgent,
        navigation,
    ]);

    useEffect(() => {
        if (dateRangeParams.startDate && dateRangeParams.endDate) {
            const startDate = dateRangeParams.startDate;
            const endDate = dateRangeParams.endDate;

            onboardingStore.fetchSegmentationData({ startDate, endDate });
            onboardingStore.fetchOnboardingData({ startDate, endDate });
            onboardingStore.fetchProgressionData({ startDate, endDate });

            const diff = moment(endDate).diff(moment(startDate), 'days') + 1;
            const priorStart = moment(startDate)
                .subtract(diff, 'days')
                .utc()
                .toISOString();
            const priorEnd = moment(startDate)
                .subtract(1, 'days')
                .utc()
                .toISOString();

            onboardingStore.fetchSegmentationData({
                startDate: priorStart,
                endDate: priorEnd,
                periodKey: 'previousPeriod',
            });
            onboardingStore.fetchOnboardingData({
                startDate: priorStart,
                endDate: priorEnd,
                periodKey: 'previousPeriod',
            });
            onboardingStore.fetchProgressionData({
                startDate: priorStart,
                endDate: priorEnd,
                periodKey: 'previousPeriod',
            });

            setShowWarning(false);
        } else {
            setShowWarning(true);
        }
    }, [dateRangeParams, onboardingStore]);

    const handleDateChange = (values: RangeValue<moment.Moment>) => {
        if (values && values[0] && values[1]) {
            const startDate = values[0].startOf('day').toISOString();
            const endDate = values[1].endOf('day').toISOString();
            setManyParamsAndGoToFirstPage({ startDate, endDate });
            setShowWarning(false);
        } else {
            setShowWarning(true);
        }
    };

    return (
        <div className={style.page_content}>
            <PageHeader title="ONBOARDING DATA" />
            <div className={style.verticalContainer}>
                <div className={style.date_picker_header}>
                    <RangePicker
                        format="YYYY-MM-DD"
                        onChange={handleDateChange}
                        value={
                            dateRangeParams.startDate && dateRangeParams.endDate
                                ? [
                                      moment(dateRangeParams.startDate),
                                      moment(dateRangeParams.endDate),
                                  ]
                                : null
                        }
                    />
                </div>
                {showWarning && (
                    <div className={style.warning_banner}>
                        <ExclamationCircleOutlined
                            style={{ fontSize: '24px', color: '#faad14' }}
                        />
                        <span>Please select a valid date range.</span>
                    </div>
                )}
                <div className={style.container}>
                    <OnboardingProgressionSection />
                    <SegmentationSurveySection />
                    <OnboardingStepsSection />
                </div>
            </div>
        </div>
    );
});
