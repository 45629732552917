import { ReactComponent as AccountCreatedIcon } from '../../icons/User.svg';
import { ReactComponent as PhoneConfirmationIcon } from '../../icons/onboarding/PhoneConfirmation.svg';
import { ReactComponent as ProfileCompletedIcon } from '../../icons/onboarding/ProfileCompleted.svg';
import { ReactComponent as SegmentationAnsweredIcon } from '../../icons/onboarding/SegmentationAnswered.svg';
import { ReactComponent as PermissionAskedIcon } from '../../icons/onboarding/PermissionAsked.svg';
import { ReactComponent as CongratulationPageIcon } from '../../icons/onboarding/CongratulationPage.svg';
import { ReactComponent as SegmentationLegacyUserIcon } from '../../icons/onboarding/SegmentationLegacyUser.svg';
import { ReactComponent as CarwashIcon } from '../../icons/Carwash.svg';
import { ReactComponent as UserAction } from '../../icons/UserAction.svg';
import { ReactComponent as Strategy } from '../../icons/Strategy.svg';
import { ReactComponent as Ticket } from '../../icons/Ticket.svg';

export const friendlyNames: Record<string, string> = {
    phone_confirmation_setup: 'Phone Confirmation',
    profile_setup: 'Profile Completed',
    account_setup: 'Account Created',
    user_segmentation_setup: 'Segmentation Answered',
    permissions_setup: 'Permission Asked',
    congratulations_setup: 'Congratulation Page',
    user_segmentation_survey: 'Segmentation Legacy User',
};

export const iconsMap: Record<
    string,
    React.FC<React.SVGProps<SVGSVGElement>>
> = {
    phone_confirmation_setup: PhoneConfirmationIcon,
    profile_setup: ProfileCompletedIcon,
    account_setup: AccountCreatedIcon,
    user_segmentation_setup: SegmentationAnsweredIcon,
    permissions_setup: PermissionAskedIcon,
    congratulations_setup: CongratulationPageIcon,
    user_segmentation_survey: SegmentationLegacyUserIcon,
    IN_PROGRESS: PhoneConfirmationIcon,
    COMPLETED: ProfileCompletedIcon,
    NOT_STARTED: SegmentationAnsweredIcon,
    Carwash: CarwashIcon,
    Other: UserAction,
    Coupons: Ticket,
    Games: Strategy,
};

export const calculateDifference = (
    current: number,
    previous: number
): number => {
    if (previous === 0) {
        return 0;
    }
    return ((current - previous) / previous) * 100;
};
